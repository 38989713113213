import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { TextVariantWithModifiers } from "@assets/style/appMuiTheme"
import styleIf from "@assets/style/util/styleIf"
import { DiscoLink, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core/styles"
import classNames from "classnames"
import React, { useRef } from "react"

export interface DiscoEmptyStateProps {
  testid: string
  icon?: React.ReactNode
  title?: React.ReactNode
  subtitle?: React.ReactNode
  customSubtitleVariant?: TextVariantWithModifiers
  buttons?: React.ReactNode
  banner?: React.ReactNode
  footer?: React.ReactNode
  className?: string
  onClick?: () => void
  align?: "center" | "left" | "right"
  variant?: DiscoEmptyStateVariant
  flow?: "column" | "row" | "row-reverse" | "column-reverse"
  helpLink?: string
}

type DiscoEmptyStateVariant = "section" | "page" | "compact"

enum ALIGNMENT {
  center = "center",
  left = "flex-start",
  right = "flex-end",
}

type StyleConfig = {
  titleVariant: TextVariantWithModifiers
  subtitleVariant: TextVariantWithModifiers
}

const STYLES: Record<DiscoEmptyStateVariant, StyleConfig> = {
  section: {
    titleVariant: "body-lg-600",
    subtitleVariant: "body-md",
  },
  page: {
    titleVariant: "heading-lg",
    subtitleVariant: "body-md",
  },
  compact: {
    titleVariant: "body-md-600",
    subtitleVariant: "body-sm",
  },
}

function DiscoEmptyState({ ...props }: DiscoEmptyStateProps) {
  const {
    testid,
    icon,
    title,
    subtitle,
    buttons,
    banner,
    footer,
    onClick,
    className,
    variant = "section",
    customSubtitleVariant,
    align = "center",
    flow = "column",
    helpLink,
  } = props

  const ref = useRef<HTMLDivElement | null>(null)
  const theme = useTheme()

  const classes = useStyles({
    align: ALIGNMENT[align],
    flow,
    variant,
    onClick: Boolean(onClick),
  })

  const subtitleVariant = customSubtitleVariant || STYLES[variant].subtitleVariant

  return (
    <div
      ref={ref}
      data-testid={`${testid}.empty-state`}
      className={classNames(classes.root, className)}
      onClick={onClick}
      onKeyPress={handleKeyDown}
      role={onClick ? "button" : undefined}
      tabIndex={onClick ? 0 : undefined}
    >
      <div className={classes.emptyStateContainer}>
        <div className={classes.content}>
          {/* Banner */}
          {banner}
          <div className={classes.titles}>
            {/* Icon */}
            {icon && <div className={classes.icon}>{icon}</div>}

            {/* Title */}
            {title && (
              <DiscoText
                variant={STYLES[variant].titleVariant}
                align={align}
                testid={`${testid}.title`}
              >
                {title || "There is nothing to display"}
              </DiscoText>
            )}

            {/* Subtitle */}
            {subtitle && (
              <>
                <DiscoText
                  variant={subtitleVariant}
                  color={"text.secondary"}
                  align={align}
                  testid={`${testid}.subtitle`}
                >
                  {subtitle}{" "}
                  {helpLink && (
                    <DiscoLink
                      to={helpLink}
                      textDecoration={"underline"}
                      color={theme.palette.primary.main}
                      textVariant={"body-md"}
                      linkIcon={true}
                      target={"_blank"}
                    >
                      {"Learn More"}
                    </DiscoLink>
                  )}
                </DiscoText>
              </>
            )}
          </div>
        </div>
        {/* CTA buttons */}
        {buttons && <div className={classes.buttons}>{buttons}</div>}
        {footer && <div>{footer}</div>}
      </div>
    </div>
  )

  function handleKeyDown() {
    if (!onClick) return
    const isFocused = document.activeElement === ref.current
    if (!isFocused) return
    onClick()
  }
}

type StyleProps = {
  onClick: boolean
  align: "center" | "flex-start" | "flex-end"
  flow: "column" | "row" | "row-reverse" | "column-reverse"
  variant: DiscoEmptyStateVariant
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    padding: ({ variant }: StyleProps) =>
      variant === "compact" ? theme.spacing(2) : theme.spacing(4),
    width: "100%",
    cursor: ({ onClick }: StyleProps) => (onClick ? "pointer" : "default"),
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  emptyStateContainer: ({ flow, variant }: StyleProps) => ({
    // default styles
    maxWidth: "676px",
    width: "100%",
    padding: variant === "compact" ? theme.spacing(2) : theme.spacing(2, 4),
    display: "flex",
    flexDirection: flow,
    justifyContent: "space-around",
    alignItems: "center",
    ...styleIf(variant === "page", {
      maxWidth: "1100px",
    }),
    gap: theme.spacing(2.5),
  }),
  icon: () => ({
    display: "flex",
    justifyContent: "center",
  }),
  content: ({ align, variant }: StyleProps) => ({
    // default styles
    "& > div": {
      justifyContent: align,
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(3),
    ...styleIf(variant === "page", {
      maxWidth: "434px",
    }),
  }),
  buttons: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
  },
  titles: {
    gap: theme.spacing(1),
    maxWidth: "536px",
  },
}))

export default DiscoEmptyState
