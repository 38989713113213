/**
 * @generated SignedSource<<2d9897b5c7c42898fe85b7c8c0cdefdb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductStatus = "archived" | "draft" | "published" | "unlisted" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type LearnModeSwitchFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly status: ProductStatus;
  readonly viewerMembership: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "LearnModeSwitchFragment";
};
export type LearnModeSwitchFragment$key = {
  readonly " $data"?: LearnModeSwitchFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LearnModeSwitchFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LearnModeSwitchFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembership",
      "kind": "LinkedField",
      "name": "viewerMembership",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "0fa62ad88d47ed419b0c8334f2b0b473";

export default node;
