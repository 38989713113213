import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButton,
  DiscoDropdown,
  DiscoDropdownProps,
  DiscoIcon,
  DiscoIconKindsReactNodeUnion,
  DiscoTextSkeleton,
  SelectOption,
} from "@disco-ui"
import DiscoDropdownItem, {
  DiscoDropdownItemProps,
} from "@disco-ui/dropdown/DiscoDropdownItem"
import DropdownIcon from "@disco-ui/dropdown/DropdownIcon"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { useCallback } from "react"

interface DiscoTableFilterDropdownProps<T extends SelectOption>
  extends TestIDProps,
    Omit<DiscoDropdownProps, "children" | "menuButton" | "ref"> {
  options: T[]
  onOptionSelect: (opt: T | null) => void
  selectedOption?: T | T[] | null
  dropdown?: {
    icon?: DiscoIconKindsReactNodeUnion | React.ReactElement | null
    label: DiscoDropdownItemProps["title"]
    sublabel?: DiscoDropdownItemProps["subtitle"]
  }
  classes?: {
    paper?: string
    text?: string
    button?: string
  }
  truncateText?: number
  rightIcon?: React.ReactNode
}

function DiscoTableFilterDropdown<T extends SelectOption>({
  testid,
  options,
  onOptionSelect,
  selectedOption,
  dropdown,
  isNested = false,
  classes: customClasses,
  truncateText,
  rightIcon,
  ...rest
}: DiscoTableFilterDropdownProps<T>) {
  const classes = useStyles()

  const selectedOptions = getSelectedOptions()
  const firstSelectedOption = selectedOptions[0]

  const title = dropdown?.label || firstSelectedOption?.title || "Filter"

  return (
    <DiscoDropdown
      testid={testid}
      menuClasses={{
        paper: classNames(customClasses?.paper, classes.menu),
      }}
      isNested={isNested}
      menuButton={useCallback(
        ({ onClick, isOpen, color, variant, rightIcon: defaultRightIcon }) =>
          isNested ? (
            <DiscoDropdownItem
              testid={`${testid}.dropdown-button`}
              title={title}
              subtitle={dropdown?.sublabel}
              icon={dropdown?.icon}
              selected={isOpen}
              onClick={onClick}
              rightButton={
                rightIcon || <DiscoIcon className={classes.chevron} icon={"chevron"} />
              }
              truncateText={truncateText}
              classes={{
                textContainer: customClasses?.text,
              }}
            />
          ) : (
            <DiscoButton
              onClick={onClick}
              color={color}
              variant={variant}
              rightIcon={defaultRightIcon}
              testid={`${testid}.dropdown-button`}
              className={classNames(customClasses?.button || classes.dropdownButton)}
            >
              {title}
            </DiscoButton>
          ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [title, isNested, dropdown?.sublabel, dropdown?.icon]
      )}
      {...rest}
    >
      {/* Filter Options */}
      {options.map((option, index) => {
        // If the option is selected, or if there is no selected option and the option's
        // value is null/undefined
        const isActive =
          selectedOptions.some((o) => o.value === option.value) ||
          (!selectedOption && option.value == null)

        return (
          <DiscoDropdownItem
            key={option.value}
            title={option.title}
            testid={
              option.testid ? `${testid}.${option.testid}` : `${testid}.item-${index}`
            }
            onClick={() => handleOptionSelect(option)}
            rightButton={
              isActive && <DiscoIcon icon={"check"} className={classes.checkIcon} />
            }
          />
        )
      })}
    </DiscoDropdown>
  )

  function getSelectedOptions() {
    if (!selectedOption) return []
    return Array.isArray(selectedOption) ? selectedOption : [selectedOption]
  }

  function handleOptionSelect(option: T) {
    // If the value of the option is null, then we want to set the selected option to null
    if (option.value === null) onOptionSelect(null)
    else onOptionSelect(option)
  }
}

const useStyles = makeUseStyles((theme) => ({
  dropdownButton: {
    minWidth: "140px",
    justifyContent: "space-between",
  },
  menu: {
    minWidth: "200px",
  },
  checkIcon: {
    marginLeft: theme.spacing(1),
  },
  chevron: {
    transform: "rotate(90deg)",
    marginLeft: theme.spacing(3),
  },
}))

export const DiscoTableFilterDropdownSkeleton = () => {
  const classes = useStyles()

  return (
    <DiscoButton
      rightIcon={<DropdownIcon />}
      color={"grey"}
      variant={"outlined"}
      className={classes.dropdownButton}
    >
      <DiscoTextSkeleton width={75} />
    </DiscoButton>
  )
}

export default DiscoTableFilterDropdown
