/**
 * @generated SignedSource<<5d7d00b7b825913193dc37d24c42db00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Discount_displayValue$data = {
  readonly valueAmount: number;
  readonly valueKind: string;
  readonly " $fragmentType": "Discount_displayValue";
};
export type Discount_displayValue$key = {
  readonly " $data"?: Discount_displayValue$data;
  readonly " $fragmentSpreads": FragmentRefs<"Discount_displayValue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Discount_displayValue",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "valueKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "valueAmount",
      "storageKey": null
    }
  ],
  "type": "Discount",
  "abstractKey": null
};

(node as any).hash = "4f8299641c3996603616f90f3344b90a";

export default node;
