/**
 * @generated SignedSource<<128dbcbd995bb76eee41df3f2df931a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetTranscriptionStatus = "draft" | "published" | "%future added value";
export type DiscoInteractivePlayerQuery$variables = {
  id: string;
};
export type DiscoInteractivePlayerQuery$data = {
  readonly node: {
    readonly __typename: "Asset";
    readonly id: string;
    readonly transcription: {
      readonly chapters: ReadonlyArray<{
        readonly startTime: number;
        readonly timecode: string;
        readonly title: string;
      }> | null;
      readonly id: string;
      readonly status: AssetTranscriptionStatus;
      readonly summary: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"InteractivePlayerOverviewTabContentFragment" | "InteractivePlayerTranscriptTabContentFragment">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"DiscoVideoFragment" | "InteractivePlayerSubtitlesTabContentFragment" | "InteractivePlayerTranscriptionStatusFragment">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type DiscoInteractivePlayerQuery = {
  response: DiscoInteractivePlayerQuery$data;
  variables: DiscoInteractivePlayerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summary",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetTranscriptionChapter",
  "kind": "LinkedField",
  "name": "chapters",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timecode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscoInteractivePlayerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DiscoVideoFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InteractivePlayerTranscriptionStatusFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InteractivePlayerSubtitlesTabContentFragment"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetTranscription",
                "kind": "LinkedField",
                "name": "transcription",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "InteractivePlayerOverviewTabContentFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "InteractivePlayerTranscriptTabContentFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Asset",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DiscoInteractivePlayerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "muxAssetPlaybackId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aspectRatio",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoDuration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoAssetsEnabledAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetTranscriptionJob",
                "kind": "LinkedField",
                "name": "transcriptionJob",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sieveJobErrorReason",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetTranscription",
                "kind": "LinkedField",
                "name": "transcription",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetTranscriptionSegment",
                    "kind": "LinkedField",
                    "name": "segments",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "start",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startTimecode",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Asset",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e667f5250edabbe62a93cc4c36f0e10",
    "id": null,
    "metadata": {},
    "name": "DiscoInteractivePlayerQuery",
    "operationKind": "query",
    "text": "query DiscoInteractivePlayerQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Asset {\n      id\n      ...DiscoVideoFragment\n      ...InteractivePlayerTranscriptionStatusFragment\n      ...InteractivePlayerSubtitlesTabContentFragment\n      transcription {\n        id\n        status\n        summary\n        chapters {\n          startTime\n          timecode\n          title\n        }\n        ...InteractivePlayerOverviewTabContentFragment\n        ...InteractivePlayerTranscriptTabContentFragment\n      }\n    }\n    id\n  }\n}\n\nfragment DiscoVideoFragment on Asset {\n  id\n  url\n  name\n  muxAssetPlaybackId\n  aspectRatio\n  videoDuration\n}\n\nfragment InteractivePlayerOverviewTabContentFragment on AssetTranscription {\n  id\n  summary\n  chapters {\n    title\n    startTime\n    timecode\n  }\n}\n\nfragment InteractivePlayerSubtitlesTabContentFragment on Asset {\n  id\n}\n\nfragment InteractivePlayerTranscriptTabContentFragment on AssetTranscription {\n  ...TranscriptReaderFragment\n}\n\nfragment InteractivePlayerTranscriptionStatusFragment on Asset {\n  id\n  videoAssetsEnabledAt\n  transcriptionJob {\n    status\n    sieveJobErrorReason\n    id\n  }\n  transcription {\n    id\n  }\n}\n\nfragment TranscriptReaderFragment on AssetTranscription {\n  segments {\n    text\n    start\n    startTimecode\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff99731b9749f5781f68ccca72b6a978";

export default node;
