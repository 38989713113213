/**
 * @generated SignedSource<<1aab490f55adedf9c04a80012f1d7631>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductRegistrationAvailability = "hidden" | "private" | "public" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductMultiSelect_ProductFragment$data = {
  readonly id: string;
  readonly isAutoJoined: boolean;
  readonly name: string;
  readonly registrationAvailability: ProductRegistrationAvailability;
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"MembershipPlanExperienceDetailsFragment" | "ProductDropdownItemFragment" | "ProductWithDetailsFragment">;
  readonly " $fragmentType": "ProductMultiSelect_ProductFragment";
};
export type ProductMultiSelect_ProductFragment$key = {
  readonly " $data"?: ProductMultiSelect_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductMultiSelect_ProductFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductMultiSelect_ProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationAvailability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAutoJoined",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductDropdownItemFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductWithDetailsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MembershipPlanExperienceDetailsFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "a93ac7f44e9adbde8496e5528ab4daf0";

export default node;
