import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { Spacing } from "@assets/style/appMuiTheme"
import mergeClasses from "@assets/style/util/mergeClasses"
import styleIf from "@assets/style/util/styleIf"
import {
  DiscoDivider,
  DiscoIcon,
  DiscoIconButton,
  DiscoSection,
  DiscoSectionProps,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoTableEmptyCell from "@disco-ui/table/cell/DiscoTableEmptyCell"
import DiscoTableRow from "@disco-ui/table/row/DiscoTableRow"
import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { Skeleton } from "@material-ui/lab"
import { range } from "@utils/array/arrayUtils"
import useOnWindowResize from "@utils/hook/useOnWindowResize"
import { TestIDProps } from "@utils/typeUtils"
import { default as classNames, default as classnames } from "classnames"
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { FetchPolicy } from "relay-runtime"

export type DiscoTableHeaderCell = (
  | {
      value: string
      key?: never
    }
  | {
      value: Exclude<ReactNode, "string">
      /** requires a key for non-string element */
      key: string
    }
) &
  TableCellProps

export interface DiscoTableProps extends TestIDProps {
  /** Need to set the inital activePage for proper backwards pagination */
  activePage: number
  setActivePage: React.Dispatch<React.SetStateAction<number>>
  /** Number of rows to return */
  rowsPerPage: number
  /** Row to render that is a React Element */
  rows: React.ReactElement[]
  /** Values for the connection */
  connection: {
    cursorsList: string[] | undefined
    totalCount: number
    pageInfo: {
      endCursor: string | null | undefined
      startCursor: string | null | undefined
    }
  }
  /** Function that refetches the provided query when paginating */
  onPaginate: (
    args: {
      first?: number
      after?: string | null
      last?: number
      before?: string | null
    },
    policy: { fetchPolicy: FetchPolicy }
  ) => void
  /** An array of DiscoTableHeaderCell to render, value is required and additional props are optional */
  header?: DiscoTableHeaderCell[]
  /** Can pass custom filter fields or buttons to the header */
  headerButtons?: React.ReactElement
  headerFilterTags?: React.ReactNode
  footer?: React.ReactElement
  hideFooter?: boolean
  customClasses?: Partial<ClassNameMap<"root" | "section">>
  emptyState?: React.ReactNode
  filterDeps?: React.DependencyList
  /** DiscoSection props */
  sectionProps?: Pick<DiscoSectionProps, keyof Spacing | "groovyDepths">
  scrollable?: boolean
  maxHeight?: string | number
  stickyFirstColumn?: boolean
  // Allows adding subheader titles for groups of rows. Rows MUST be sorted in the same
  // order as the groups in this array for this to work properly.
  rowGroups?: {
    title: string
    isInGroup: (rowIndex: number) => boolean
    testid?: string
  }[]
  columnWidths?: string[]
  border?: boolean
}

/** Table component with backwards and forwards pagination*/
const DiscoTable = (props: DiscoTableProps) => {
  const {
    activePage,
    setActivePage,
    rowsPerPage,
    testid = "DiscoTable",
    rows,
    header,
    connection,
    onPaginate,
    headerButtons,
    headerFilterTags,
    footer,
    hideFooter,
    customClasses,
    emptyState,
    sectionProps,
    filterDeps = [],
    scrollable = false,
    maxHeight,
    stickyFirstColumn = false,
    rowGroups,
    columnWidths,
    border = false,
  } = props

  // Hide the drop shadow on sticky column when scrolled completely to the left
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [isScrolled, setIsScrolled] = useState(false)
  const handleScroll = useCallback(() => {
    if (containerRef.current) setIsScrolled(Boolean(containerRef.current.scrollLeft))
  }, [])
  const setContainerRef = useCallback(
    (e: HTMLDivElement | null) => {
      containerRef.current = e
      if (e) handleScroll()
    },
    [handleScroll]
  )
  useOnWindowResize(handleScroll)

  const classes = useStyles({
    stickyFirstColumn,
    maxHeight,
    isScrolled,
    hasColumnWidths: Boolean(columnWidths),
    border,
  })
  const theme = useTheme()

  // Set the window of indices to render from the records, this is necessary
  // so that pagination works backwards and forwards
  const [visibleWindow, setVisibleWindow] = useState({ start: 0, end: rowsPerPage })

  // If the table parameters are changed (rowsPerPage, anything provided in the header buttons),
  // requery and set start the pagination at page 1 with a fresh visible window
  useEffect(() => {
    const start = (activePage - 1) * rowsPerPage
    // If out of range, default to first page
    if (activePage > 1 && connection.totalCount <= start) {
      setActivePage(1)
      setVisibleWindow({ start: 0, end: rowsPerPage })
    } else {
      // Update the visible window when activePage is changed in the parent
      const end = start + rowsPerPage
      if (visibleWindow.start !== start || visibleWindow.end !== end) {
        setVisibleWindow({ start, end })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, rowsPerPage, activePage, headerButtons])

  useEffect(() => {
    // If the filters change, reset the visible window
    setActivePage(1)
    setVisibleWindow({ start: 0, end: rowsPerPage })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, filterDeps)

  const table = (
    <Table
      classes={mergeClasses({ root: classes.root }, customClasses)}
      data-testid={testid}
      stickyHeader={scrollable}
    >
      {/* Header */}
      {(header || []).length > 0 && (
        <TableHead className={classes.headerRow}>
          <DiscoTable.Row>
            {header!.map(({ value, key, ...cellProps }) => (
              <TableCell key={typeof value === "string" ? value : key} {...cellProps}>
                {typeof value === "string" ? (
                  <DiscoText variant={"body-xs-600"} noWrap>
                    {value}
                  </DiscoText>
                ) : (
                  value
                )}
              </TableCell>
            ))}
          </DiscoTable.Row>
        </TableHead>
      )}

      {columnWidths && (
        <colgroup>
          {columnWidths.map((width, i) => {
            // eslint-disable-next-line react/no-array-index-key
            return <col key={`${width}-${i}`} span={1} style={{ width }} />
          })}
        </colgroup>
      )}

      {/* Rows */}
      <TableBody data-testid={`${testid}.body`}>{renderRows()}</TableBody>
    </Table>
  )

  const previousButtonDisabled = visibleWindow.start === 0
  const nextButtonDisabled = visibleWindow.end >= connection.totalCount
  const onlyHasOnePageOfResults = connection.totalCount <= rowsPerPage

  return (
    <DiscoSection
      padding={2.5}
      groovyDepths={sectionProps?.padding === 0 ? undefined : "insideCard"}
      border={sectionProps?.padding !== 0}
      {...sectionProps}
      className={classnames(classes.section, customClasses?.section)}
    >
      {/* Header buttons */}
      {headerButtons && <div className={classes.headerButtons}>{headerButtons}</div>}
      {/* Header filter tags */}
      {headerFilterTags && (
        <div className={classes.headerFilterTagsContainer}>{headerFilterTags}</div>
      )}
      {/* Table */}
      {scrollable ? (
        <TableContainer
          ref={setContainerRef}
          className={classes.tableContainer}
          onScroll={handleScroll}
        >
          {table}
        </TableContainer>
      ) : (
        table
      )}

      {/* Footer */}
      {!hideFooter &&
        (footer ? (
          <>{footer}</>
        ) : (
          // Don't render footer if there are no results
          connection.totalCount > 0 && (
            <div className={classes.paginationRow}>
              <DiscoDivider color={theme.palette.groovy.neutral[100]} thickness={2} />
              <div className={classes.footer}>
                {/* Backward Pagination */}
                {!onlyHasOnePageOfResults && (
                  <DiscoIconButton
                    testid={`${testid}.pagination-row.previous-button`}
                    className={classes.backwardPaginationIconButton}
                    disabled={previousButtonDisabled}
                    onClick={() => handlePaginate("previous")}
                    defaultIconStyles={false}
                  >
                    <DiscoIcon
                      icon={"chevron"}
                      width={16}
                      height={16}
                      rotate={"-90"}
                      active={!previousButtonDisabled}
                    />
                    <DiscoText
                      color={previousButtonDisabled ? "text.disabled" : undefined}
                    >
                      {"Prev"}
                    </DiscoText>
                  </DiscoIconButton>
                )}

                <DiscoText color={"text.secondary"}>
                  {`Page ${activePage} of ${Math.ceil(
                    connection.totalCount / rowsPerPage
                  )}`}
                </DiscoText>

                {/* Forward Pagination */}
                {!onlyHasOnePageOfResults && (
                  <DiscoIconButton
                    testid={`${testid}.pagination-row.next-button`}
                    className={classnames(classes.forwardPaginationIconButton)}
                    disabled={nextButtonDisabled}
                    onClick={() => handlePaginate("next")}
                    defaultIconStyles={false}
                  >
                    <DiscoText color={nextButtonDisabled ? "text.disabled" : undefined}>
                      {"Next"}
                    </DiscoText>
                    <DiscoIcon
                      icon={"chevron"}
                      width={16}
                      height={16}
                      rotate={"90"}
                      active={!nextButtonDisabled}
                    />
                  </DiscoIconButton>
                )}
              </div>
            </div>
          )
        ))}
    </DiscoSection>
  )

  /** Paginate the table forwards or backwards, setting the activePage and visibleWindow,
   * only refetch if paginating forward, since no records are being removed when paginating
   * backwards. The visible window is set to the size of `rowsPerPage`, and can overflow
   * to be greater than the `totalCount` of records
   */
  function handlePaginate(action: "previous" | "next") {
    if (action === "previous") {
      // Update the page
      setActivePage(activePage - 1)

      // Update visibleWindow
      const start = Math.max(0, visibleWindow.start - rowsPerPage)
      const end = Math.min(connection.totalCount, visibleWindow.end - rowsPerPage)
      setVisibleWindow({
        start,
        end,
      })

      refetch({
        action: "previous",
        first: rowsPerPage,
        start,
        end,
      })
    } else {
      // Update the page
      setActivePage(activePage + 1)

      // Update visibleWindow
      const start = Math.max(0, visibleWindow.start + rowsPerPage)
      const end = visibleWindow.end + rowsPerPage
      setVisibleWindow({
        start,
        end,
      })

      refetch({
        action: "next",
        start,
        end,
      })
    }
  }

  /** Refetch the provided query
   * @param action which direction of pagination
   * @param first first `n` number of records to return
   * @param after `GlobalID` of the `endCursor` to continue the pagination
   * @param fetchPolicy default `store-and-network` unless provided
   * @param start
   * @param end end of visible window
   */
  function refetch(args: {
    action?: "previous" | "next"
    first?: number
    after?: string | null
    fetchPolicy?: string
    start: number
    end: number
  }) {
    // Get the cursor for the before pagination, and after pagination (depends on direction)
    const startCursor = connection.cursorsList ? connection.cursorsList[args.start] : null
    const endCursor = connection.cursorsList ? connection.cursorsList[args.end - 1] : null

    if (args.action === "previous") {
      onPaginate(
        {
          last: args.first || rowsPerPage,
          before: startCursor || args.after || connection.pageInfo.startCursor || null,
        },
        { fetchPolicy: "network-only" }
      )
    } else {
      onPaginate(
        {
          first: args.first || rowsPerPage,
          after: endCursor || connection.pageInfo.endCursor || null,
        },
        { fetchPolicy: "network-only" }
      )
    }
  }

  function renderRows() {
    const visibleStart = visibleWindow.start || 0
    const visibleRows = rows.slice(visibleStart, visibleWindow.end || rows.length)

    // If not grouping rows under subheaders, we can return visible rows as-is
    if (!rowGroups?.length) {
      if (rows.length === 0) return getEmptyRow()
      return visibleRows
    }

    const groupedRows = []
    let groupIndex = 0
    let subheaderOutput = -1
    for (let visibleIndex = 0; visibleIndex < visibleRows.length; visibleIndex++) {
      // Add row group subheaders before the first row that matches each condition, or the
      // group was skipped over without any matches. This assumes a match on a previous
      // page for the leading groups if active page > 1, and won't render emptyRow.
      while (groupIndex < rowGroups.length) {
        const { isInGroup, title, testid: groupTestid } = rowGroups[groupIndex]
        if (isInGroup(visibleStart + visibleIndex)) {
          if (subheaderOutput < groupIndex) {
            groupedRows.push(getGroupSubheader(title, groupTestid))
            subheaderOutput = groupIndex
          }
          break
        } else if (
          subheaderOutput === groupIndex - 1 &&
          (groupIndex > 0 || activePage === 1)
        ) {
          groupedRows.push(
            getGroupSubheader(title, groupTestid),
            getEmptyRow(groupTestid)
          )
          subheaderOutput = groupIndex
        }
        groupIndex++
      }

      groupedRows.push(visibleRows[visibleIndex])
    }

    // If on last page, output subheader and empty state for any remaining groups
    if (activePage >= connection.totalCount / rowsPerPage) {
      while (subheaderOutput < rowGroups.length - 1) {
        subheaderOutput++
        const { title, testid: groupTestid } = rowGroups[subheaderOutput]
        groupedRows.push(getGroupSubheader(title, groupTestid))
        groupedRows.push(getEmptyRow(groupTestid))
      }
    }

    return groupedRows
  }

  function getGroupSubheader(title: string, groupTestid?: string) {
    return (
      <DiscoTable.Row testid={groupTestid} hover={false}>
        <TableCell colSpan={4} className={classes.groupSubheader}>
          <DiscoText variant={"body-sm"} color={"groovy.neutral.400"}>
            {title}
          </DiscoText>
        </TableCell>
      </DiscoTable.Row>
    )
  }

  function getEmptyRow(groupTestid?: string) {
    return (
      <DiscoTable.Row testid={`${groupTestid}.empty`} hover={false}>
        <TableCell colSpan={9999} align={"center"}>
          {emptyState || <DiscoText>{"No results"}</DiscoText>}
        </TableCell>
      </DiscoTable.Row>
    )
  }
}

type StyleProps = {
  stickyFirstColumn?: boolean
  maxHeight?: string | number
  isScrolled?: boolean
  hasColumnWidths?: boolean
  border?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  section: {
    display: "grid",
    overflowX: "auto",
  },
  root: ({ stickyFirstColumn, isScrolled, hasColumnWidths, border }: StyleProps) => ({
    display: "table",
    overflowY: "auto",
    /** table row spacing */
    borderSpacing: stickyFirstColumn ? 0 : `0 ${theme.spacing(1)}px`,

    ...styleIf(hasColumnWidths, {
      tableLayout: "fixed",
      width: "100%",
    }),

    ...styleIf(!border, {
      border: "none",
      borderCollapse: "separate",
    }),

    "& thead, tfoot": {
      borderStyle: "hidden",
      borderBottom: "none",
      borderCollapse: "separate",
    },

    "& td, tr": {
      borderTop: theme.palette.constants.tableBorder,

      ...styleIf(!border, {
        borderStyle: "hidden",
        borderBottom: "none",
        borderCollapse: "separate",
      }),
    },
    "& td, th": {
      padding: theme.spacing(1),
    },
    "& th": {
      // Don't let headers stick to the left side and slide over each other
      left: "unset",
    },
    ...styleIf(stickyFirstColumn, {
      "& th:first-child::after, td:first-child::after": {
        content: "''",
        position: "absolute",
        top: 0,
        left: "100%",
        height: "calc(100% + 1px)",
        width: "4px",
        pointerEvents: "none",
        boxShadow: isScrolled ? `inset 4px 0px 4px -4px #2E405826` : "none",
      },
      "& th:first-child": {
        zIndex: 3,
        left: 0,
      },
      "& td:first-child": {
        position: "sticky",
        left: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: 3,
      },
      "& th:not(:first-child), td:not(:first-child)": {
        paddingLeft: theme.spacing(2),
      },
      "& tr": {
        "&:not(:first-child) td": {
          borderTop: `1.5px solid ${theme.palette.groovy.neutral[100]}`,
        },
        "&:hover td:first-child": {
          backgroundColor:
            theme.palette.type === "dark"
              ? theme.palette.groovy.onDark[500]
              : theme.palette.groovy.neutral[100],
        },
      },
    }),
  }),
  headerButtons: ({ border }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: theme.spacing(1.5),

    ...styleIf(border, {
      paddingBottom: theme.spacing(1.5),
    }),
  }),
  headerFilterTagsContainer: {
    marginTop: theme.spacing(1.5),
  },
  headerRow: {
    ...theme.typography["body-xs"],
    ...theme.typography.modifiers.fontWeight[600],
    "& .MuiTableCell-head": {
      "&:nth-child(1)": {
        [theme.breakpoints.down("xs")]: {
          paddingLeft: 0,
        },
      },
    },
    "& th": {
      /** header border radius */
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[100],
      "&:first-child": {
        borderTopLeftRadius: theme.measure.borderRadius.default,
        borderBottomLeftRadius: theme.measure.borderRadius.default,
      },
      "&:last-child": {
        borderTopRightRadius: theme.measure.borderRadius.default,
        borderBottomRightRadius: theme.measure.borderRadius.default,
      },
    },
  },
  paginationRow: {
    position: "relative",
    display: "block",
  },
  footer: {
    display: "flex",
    gap: theme.spacing(1.5),
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  backwardPaginationIconButton: {
    padding: theme.spacing(0.75, 1, 0.75, 0.5),
  },
  forwardPaginationIconButton: {
    padding: theme.spacing(0.75, 0.5, 0.75, 1),
  },
  tableContainer: ({ maxHeight }: StyleProps) => ({
    maxHeight,
    marginTop: theme.spacing(1),
    overflowY: maxHeight ? "auto" : "hidden",
  }),
  groupSubheader: {
    paddingBottom: "0 !important",
  },
}))

export const DiscoTableSkeleton: React.FC<Pick<DiscoTableProps, "rows">> = ({
  rows,
}: Pick<DiscoTableProps, "rows">) => (
  <DiscoSection>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Skeleton width={150} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.length ? (
          <>{rows}</>
        ) : (
          range(10).map((i) => (
            <TableRow key={i}>
              <TableCell>
                <Skeleton width={150} />
              </TableCell>
              <TableCell>
                <Skeleton width={150} />
              </TableCell>
              <TableCell>
                <Skeleton width={150} />
              </TableCell>
              <TableCell>
                <Skeleton variant={"circle"} width={32} height={32} />
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  </DiscoSection>
)

interface SkeletonProps
  extends Pick<DiscoTableProps, "header" | "headerButtons" | "sectionProps" | "border"> {
  rows?: number
  row?: React.ReactElement
  rowHeight?: number
  customClasses?: Partial<ClassNameMap<"root" | "section">>
}

export function DiscoTableSkeletonWithHeader(props: SkeletonProps) {
  const {
    rows = 10,
    headerButtons,
    header,
    sectionProps,
    row,
    rowHeight,
    customClasses,
    border = false,
  } = props
  const classes = useStyles({ border })
  return (
    <DiscoSection
      padding={2.5}
      groovyDepths={sectionProps?.padding === 0 ? undefined : "insideCard"}
      border={sectionProps?.padding !== 0}
      className={classnames(classes.section, customClasses?.section)}
      {...sectionProps}
    >
      {headerButtons && <div className={classes.headerButtons}>{headerButtons}</div>}
      <Table className={classNames(classes.root, customClasses?.root)}>
        {(header || []).length > 0 && (
          <TableHead className={classes.headerRow}>
            <DiscoTable.Row>
              {header!.map(({ value, key, ...cellProps }) => (
                <TableCell key={typeof value === "string" ? value : key} {...cellProps}>
                  {typeof value === "string" ? (
                    <DiscoText variant={"body-xs-600"} noWrap>
                      {value}
                    </DiscoText>
                  ) : (
                    value
                  )}
                </TableCell>
              ))}
            </DiscoTable.Row>
          </TableHead>
        )}
        <TableBody>
          {range(rows).map(
            (i) =>
              row || (
                <DiscoTable.Row key={i}>
                  {range(header?.length || 1).map((j) => (
                    <TableCell key={j}>
                      <DiscoTextSkeleton height={rowHeight} />
                    </TableCell>
                  ))}
                </DiscoTable.Row>
              )
          )}
        </TableBody>
      </Table>
    </DiscoSection>
  )
}

DiscoTable.Row = DiscoTableRow
DiscoTable.EmptyCell = DiscoTableEmptyCell

export default DiscoTable
