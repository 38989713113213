import CaretIcon from "@/core/ui/iconsax/bold/arrow-down.svg"
import ChevronIcon from "@/core/ui/iconsax/linear/arrow-up-1.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoIconButton } from "@disco-ui"
import { Collapse, Grid } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { ReactNode, useState } from "react"

interface Props extends TestIDProps {
  header: ReactNode
  body?: ReactNode
  children?: ReactNode
  isDefaultExpanded?: boolean
  variant?: "default" | "caret"
  mountOnExpand?: boolean
  rightOverflow?: ReactNode
}

function DiscoExpand({
  header,
  body,
  children,
  isDefaultExpanded = true,
  testid = "DiscoExpand",
  variant = "default",
  mountOnExpand = true,
  rightOverflow,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded)
  const classes = useStyles({ isExpanded, variant })

  return (
    <>
      <Grid
        className={classes.headerContainer}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {variant === "caret" ? null : header}
        <DiscoIconButton
          size={"small"}
          testid={`${testid}.expand-button`}
          svgStyles={variant === "caret" ? { height: 16, width: 16 } : undefined}
        >
          {variant === "caret" ? (
            <CaretIcon className={classes.caretIcon} />
          ) : (
            <ChevronIcon className={classes.chevronIcon} />
          )}
        </DiscoIconButton>
        {variant === "caret" ? header : null}
        {rightOverflow}
      </Grid>
      <Collapse in={isExpanded} mountOnEnter={mountOnExpand}>
        <div className={classes.bodyContainer}>{body ?? children}</div>
      </Collapse>
    </>
  )
}

type StyleProps = {
  isExpanded: boolean
  variant: "default" | "caret"
}

const useStyles = makeUseStyles((theme) => ({
  headerContainer: ({ variant }: StyleProps) => ({
    display: "flex",
    justifyContent: variant === "caret" ? "flex-start" : "space-between",
    gap: theme.spacing(1),
    alignItems: "center",
    cursor: "pointer",
    padding: variant === "caret" ? theme.spacing(0, 1) : 0,
    userSelect: "none",
  }),
  chevronIcon: ({ isExpanded }: StyleProps) => ({
    transition: "transform 300ms ease-in-out",
    transform: `rotate(${isExpanded ? 180 : 90}deg)`,
  }),
  caretIcon: ({ isExpanded }: StyleProps) => ({
    transform: `rotate(${isExpanded ? 0 : -90}deg)`,
  }),
  bodyContainer: {
    marginTop: theme.spacing(1.5),
  },
}))

export default DiscoExpand
