import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoButtonSize } from "@disco-ui/button/DiscoButton"
import DiscoIconButton from "@disco-ui/button/DiscoIconButton"
import DiscoIcon from "@disco-ui/icon/DiscoIcon"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"

interface DiscoExpandButtonProps extends TestIDProps {
  isOpen: boolean
  onClick: VoidFunction
  size?: DiscoButtonSize
  variant?: "default" | "outlined"
}

function DiscoExpandButton({
  testid = "DiscoExpandButton",
  isOpen,
  onClick,
  size = "medium",
  variant = "outlined",
}: DiscoExpandButtonProps) {
  const classes = useStyles({ isExpanded: isOpen, size })
  const theme = useTheme()
  return (
    <>
      <DiscoIconButton
        onClick={onClick}
        testid={testid}
        variant={variant}
        className={classes.iconButton}
        color={theme.palette.groovy.neutral[500]}
      >
        <DiscoIcon className={classes.icon} icon={"chevron"} />
      </DiscoIconButton>
    </>
  )
}

type StyleProps = {
  isExpanded: boolean
  size: DiscoButtonSize
}

const useStyles = makeUseStyles((theme) => ({
  iconButton: (props: StyleProps) => ({
    ...styleIf(props.size === "large", {
      height: 48,
      width: 48,
      padding: theme.spacing(1.5, 2, 1.5, 2),
    }),

    ...styleIf(props.size === "medium", {
      height: 40,
      width: 40,
      padding: theme.spacing(1, 1.5, 1, 1.5),
    }),

    ...styleIf(props.size === "small", {
      height: 32,
      width: 32,
      padding: theme.spacing(0.5, 1, 0.5, 1),
    }),

    ...styleIf(props.size === "xsmall", {
      height: 28,
      width: 28,
      padding: theme.spacing(0.25, 0.5, 0.25, 0.5),
    }),
  }),
  icon: ({ isExpanded }: StyleProps) => ({
    transition: "transform 300ms ease-in-out",
    transform: `rotate(${isExpanded ? 180 : 90}deg)`,
  }),
}))

export default DiscoExpandButton
