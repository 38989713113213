/**
 * @generated SignedSource<<789a59dd394b73f064aa31212b443fb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InteractivePlayerSubtitlesTabContentFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "InteractivePlayerSubtitlesTabContentFragment";
};
export type InteractivePlayerSubtitlesTabContentFragment$key = {
  readonly " $data"?: InteractivePlayerSubtitlesTabContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InteractivePlayerSubtitlesTabContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InteractivePlayerSubtitlesTabContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Asset",
  "abstractKey": null
};

(node as any).hash = "6b1b95e82908ce147a0780c93d731bd5";

export default node;
