import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import styleIf from "@assets/style/util/styleIf"
import { DiscoSpinner, DiscoTooltip } from "@disco-ui"
import DiscoLink, { DiscoLinkProps } from "@disco-ui/link/DiscoLink"
import { IconButton, IconButtonProps, lighten, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { forwardRef, ReactNode } from "react"

export interface DiscoIconButtonProps
  extends Omit<IconButtonProps, "color">,
    TestIDProps {
  children?: ReactNode
  width?: React.CSSProperties["width"]
  height?: React.CSSProperties["height"]
  color?: React.CSSProperties["color"]
  backgroundColor?: React.CSSProperties["color"]
  background?: React.CSSProperties["background"]
  hoverColor?: React.CSSProperties["color"]
  hoverBackgroundColor?: React.CSSProperties["color"]
  svgStyles?: {
    width?: React.CSSProperties["width"]
    height?: React.CSSProperties["height"]
  }
  to?: DiscoLinkProps["to"]
  variant?: DiscoIconButtonVariant
  shouldDisplaySpinner?: boolean
  tooltip?: string
  tooltipPlacement?: "top" | "bottom" | "left" | "right"
  /** Whether we want this component to decide the styling for the icon */
  defaultIconStyles?: boolean
  borderStyle?: React.CSSProperties["borderStyle"]
  linkProps?: Omit<DiscoLinkProps, "to" | "className">
  haveDisabledStyles?: boolean
}

type DiscoIconButtonVariant = "default" | "outlined" | "primary"

const DiscoIconButton = forwardRef<HTMLButtonElement, DiscoIconButtonProps>(
  (props, ref) => {
    const {
      children,
      width,
      height,
      classes,
      testid,
      color,
      hoverColor,
      backgroundColor,
      background,
      hoverBackgroundColor,
      className,
      svgStyles,
      to,
      variant = "default",
      shouldDisplaySpinner,
      tooltip,
      tooltipPlacement,
      defaultIconStyles = true,
      borderStyle,
      linkProps,
      haveDisabledStyles,
      ...rest
    } = props
    const defaultClasses = useStyles({
      width,
      height,
      color,
      backgroundColor,
      background,
      hoverBackgroundColor,
      hoverColor,
      svgHeight: svgStyles?.height,
      svgWidth: svgStyles?.width,
      variant,
      defaultIconStyles,
      borderStyle,
      haveDisabledStyles,
    })

    if (shouldDisplaySpinner) {
      return <DiscoSpinner size={"sm"} fullWidth={false} />
    }
    let content: React.ReactNode

    if (to) {
      content = (
        <DiscoLink
          {...linkProps}
          to={to}
          className={classNames(defaultClasses.root, classes?.root, classes?.label)}
          data-testid={testid}
        >
          {children}
        </DiscoLink>
      )
    } else {
      content = (
        <IconButton
          ref={ref}
          className={className}
          classes={mergeClasses(defaultClasses, classes)}
          data-testid={testid}
          {...rest}
        >
          {children}
        </IconButton>
      )
    }

    if (tooltip) {
      return (
        <DiscoTooltip content={tooltip} placement={tooltipPlacement}>
          <div>{content}</div>
        </DiscoTooltip>
      )
    }

    return <>{content}</>
  }
)

type StyleProps = Pick<
  DiscoIconButtonProps,
  | "width"
  | "height"
  | "color"
  | "backgroundColor"
  | "background"
  | "variant"
  | "hoverColor"
  | "hoverBackgroundColor"
  | "defaultIconStyles"
  | "borderStyle"
  | "haveDisabledStyles"
> & {
  svgHeight?: React.CSSProperties["height"]
  svgWidth?: React.CSSProperties["width"]
}

const useStyles = makeUseStyles((theme) => ({
  root: ({
    width,
    height,
    color,
    hoverColor,
    backgroundColor,
    background,
    hoverBackgroundColor,
    svgHeight,
    svgWidth,
    variant,
    defaultIconStyles,
    borderStyle = "solid",
    haveDisabledStyles,
  }: StyleProps) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.constants.icon,
    height,
    width,
    // if we set the width or height of the button, padding may constrain the size of the icon, so set it to 0
    padding: width || height ? 0 : undefined,
    backgroundColor: backgroundColor ?? undefined,
    background: background ?? undefined,
    ...styleIf(variant === "outlined", {
      border: `1.5px ${borderStyle} ${theme.palette.groovy.neutral[300]}`,
    }),
    "&:hover, &:active": {
      backgroundColor:
        hoverBackgroundColor ??
        (theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[200]),
      color: hoverColor ?? theme.palette.text.primary,
      "& svg": {
        color: (defaultIconStyles && hoverColor) ?? theme.palette.text.primary,
      },
    },
    "&:not(:hover) svg": {
      // For icon-only button, the default color should be grey 400
      color: (defaultIconStyles && color) ?? theme.palette.constants.icon,
    },
    "& svg": {
      height: (defaultIconStyles && svgHeight) ?? 24,
      width: (defaultIconStyles && svgWidth) ?? 24,
    },
    borderRadius: theme.measure.borderRadius.medium,
    "&.Mui-disabled": {
      ...styleIf(haveDisabledStyles, {
        cursor: "not-allowed",
        pointerEvents: "initial",
        userSelect: "none",
        opacity: 0.4,
      }),

      "& svg ": {
        color: defaultIconStyles && theme.palette.groovy.grey[300],
      },
      ...styleIf(variant === "outlined", {
        border: `1.5px ${borderStyle} ${theme.palette.groovy.neutral[200]}`,
      }),
    },
    ...styleIf(variant === "primary", {
      backgroundColor: theme.palette.primary.main,
      "& svg": {
        color: theme.palette.common.white,
      },

      "&.Mui-disabled": {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.4,
      },

      "&:hover": {
        backgroundColor: lighten(theme.palette.primary.main, 0.2),
        "& svg": {
          color: theme.palette.common.white,
        },
      },

      "&:not(:hover) svg": {
        color: theme.palette.common.white,
      },
    }),
  }),
}))

export default DiscoIconButton

interface SkeletonProps {
  className?: string
  height?: number
  width?: number
}

export function DiscoIconButtonSkeleton(props: SkeletonProps) {
  const { height, width, className } = props

  const theme = useTheme()

  return (
    <Skeleton
      variant={"rect"}
      className={className}
      style={{
        height: height ?? 48,
        width: width ?? 48,
        borderRadius: theme.measure.borderRadius.medium,
      }}
    />
  )
}
