import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoIcon, DiscoInput, DiscoInputSkeleton } from "@disco-ui"
import useDebounce from "@utils/hook/useDebounce"
import { useState } from "react"

export type DiscoTableSearchInputProps = {
  testid: string
  defaultValue?: string
  onChange: (value: string) => void
  placeholder?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  autoFocus?: boolean
  backgroundColor?: string
  inputRef?: React.MutableRefObject<HTMLInputElement | null>
  debounce?: number
}

const DiscoTableSearchInput = ({
  testid,
  onChange,
  defaultValue,
  placeholder,
  onClick,
  autoFocus,
  backgroundColor,
  inputRef,
  debounce = 1000,
}: DiscoTableSearchInputProps) => {
  const handleChange = useDebounce(onChange, debounce)
  const classes = useStyles({ backgroundColor })

  const [value, setValue] = useState<string>(defaultValue || "")

  return (
    <DiscoInput
      inputRef={inputRef}
      data-testid={`${testid}.search-input`}
      name={"title"}
      value={value}
      placeholder={placeholder ?? "Search"}
      onChange={(e) => {
        setValue(e.target.value)
        handleChange(e.target.value)
      }}
      fullWidth
      startAdornment={<DiscoIcon icon={"search"} />}
      classes={{ root: classes.root, input: classes.input }}
      onClick={onClick}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
    />
  )
}

interface StyleProps {
  backgroundColor?: string
}

const useStyles = makeUseStyles((theme) => ({
  root: ({ backgroundColor }: StyleProps) => ({
    height: "40px",
    backgroundColor,
  }),
  input: {
    marginLeft: theme.spacing(1),
  },
}))

export const DiscoTableSearchInputSkeleton = () => {
  return <DiscoInputSkeleton height={40} flexGrow={1} />
}

export default DiscoTableSearchInput
