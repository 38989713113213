import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { EmojiObject } from "@components/chat/channel/emoji-button/ChatEmoji"
import Picker from "@emoji-mart/react"
import { darken, lighten, useTheme } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"

interface DiscoEmojiPickerProps {
  onChange: (emoji: EmojiObject) => void
  onClose: () => void
  skinTonesDisabled?: boolean
}

function DiscoEmojiPicker({
  onChange,
  onClose,
  skinTonesDisabled = false,
}: DiscoEmojiPickerProps) {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const theme = useTheme()

  const isDarkmode = theme.palette.type === "dark"

  return (
    <div className={classes.emojiPicker}>
      <Picker
        skinTonePosition={skinTonesDisabled ? "none" : "auto"}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={!isMobile}
        theme={isDarkmode ? "dark" : "light"}
        previewPosition={"none"}
        onEmojiSelect={(emoji: EmojiObject) => {
          onChange(emoji)
          onClose()
        }}
      />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  // Defining the background and active tab colors
  emojiPicker: {
    "& .emoji-mart": {
      // for background of emoji picker
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.divider,
    },
    "& .emoji-mart-bar": {
      borderColor: `${theme.palette.divider} !important`,
    },
    "& .emoji-mart-category-label span": {
      // for intermediary category labels: eg: Frequently used, Smileys & People, etc
      backgroundColor: `${theme.palette.background.paper} !important`,
      color: `${theme.palette.text.secondary} !important`,
    },
    "& .emoji-mart-anchor-selected": {
      "& .emoji-mart-anchor-bar": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      "& .emoji-mart-anchor-icon svg": {
        fill: theme.palette.primary.main,
      },
    },
    "& .emoji-mart-search input": {
      backgroundColor: `${theme.palette.background.paper} !important`,
      borderColor: `${theme.palette.divider} !important`,
      color: `${theme.palette.text.primary} !important`,
    },
    "& .emoji-mart-search-icon svg": {
      fill: `${theme.palette.text.primary} !important`,
    },

    "& .emoji-mart-skin-swatches": {
      backgroundColor: `${theme.palette.background.paper} !important`, // Update skin tone picker background color
    },
    "& .emoji-mart-preview-data": {
      color: theme.palette.text.secondary,
    },
    "& .emoji-mart-category .emoji-mart-emoji:hover::before": {
      backgroundColor:
        theme.palette.type === "dark"
          ? `${darken(theme.palette.primary.dark, 0.8)} !important`
          : `${lighten(theme.palette.primary.light, 0.5)} !important`,
    },
    "& .emoji-mart-category-list": {
      cursor: "default",
    },
    "& .emoji-mart-emoji": {
      cursor: "pointer",
      "& > span": {
        cursor: "pointer !important",
      },
    },
  },
}))

export default DiscoEmojiPicker
