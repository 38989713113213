import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import DiscoIconButton from "@disco-ui/button/DiscoIconButton"
import DiscoFormControl, {
  DiscoFormControlProps,
} from "@disco-ui/form-control/DiscoFormControl"
import DiscoIcon from "@disco-ui/icon/DiscoIcon"
import DiscoInput, { DiscoInputProps } from "@disco-ui/input/DiscoInput"
import { Popover, lighten } from "@material-ui/core"
import { isHexColor } from "@utils/color/colorUtils"
import { TestIDProps } from "@utils/typeUtils"
import React, { useEffect, useRef, useState } from "react"
import { ChromePicker, ColorResult } from "react-color"

type FormControlPropsSubset = Pick<
  DiscoFormControlProps,
  "label" | "errorMessages" | "marginBottom" | "disabled"
>

type Props = Omit<DiscoInputProps, "onChange" | "ref" | "endAdornment"> &
  TestIDProps & {
    value: string | null | undefined
    hideText?: boolean
    onChange(v: string): void
    presets?: string[]
    formControlProps?: FormControlPropsSubset
  }

const DiscoColorPicker: React.FC<Props> = (props) => {
  const {
    value,
    onChange,
    disabled,
    hideText = false,
    testid,
    presets,
    formControlProps,
    ...rest
  } = props

  const [showPicker, setShowPicker] = useState(false)
  const inputRef = useRef<HTMLElement | null>(null)
  const classes = useStyles()
  const isValid = isHexColor(value)
  const [renderedValue, setRenderedValue] = useState(value)

  const defaultErrorMessages = isValid ? [] : ["Must be a valid color, ex: #FFFFFF"]

  const {
    label,
    errorMessages = defaultErrorMessages,
    marginBottom,
    disabled: formControlDisabled = disabled,
    ...otherFormControlProps
  } = formControlProps || {}

  const handleColorChange = (data: ColorResult) => {
    const alphaHex = data.rgb.a
      ? Math.round(data.rgb.a * 255)
          .toString(16)
          .padStart(2, "0")
      : "00"
    const hexWithAlpha = `${data.hex}${alphaHex}`
    onChange(hexWithAlpha)
    setRenderedValue(hexWithAlpha)
  }

  // If the value is now becoming a valid color, we should update the rendered value
  useEffect(() => {
    if (!isValid) return
    if (renderedValue === value) return
    if (renderedValue === "#") return
    setRenderedValue(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <>
      <DiscoFormControl
        error={!isValid}
        errorMessages={errorMessages}
        disabled={formControlDisabled}
        label={label}
        marginBottom={marginBottom}
        {...otherFormControlProps}
      >
        <DiscoInput
          ref={(e) => (inputRef.current = e)}
          type={hideText ? "hidden" : ""}
          value={renderedValue}
          onChange={handleOnChange}
          endAdornment={
            <button
              className={classes.colorBadge}
              style={{ background: renderBackground() || undefined }}
              onClick={handleOnClick}
            />
          }
          disabled={disabled}
          data-testid={testid}
          {...rest}
          inputProps={{ maxLength: 9, ...rest.inputProps }}
        />
      </DiscoFormControl>

      {showPicker && (
        <Popover
          open={showPicker}
          onClose={() => setShowPicker(false)}
          anchorEl={inputRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          classes={{ paper: classes.paper }}
        >
          <ChromePicker
            color={renderBackground() || undefined}
            onChange={handleColorChange}
          />
        </Popover>
      )}
      {presets?.length && (
        <div className={classes.presetsContainer}>
          {presets.map((preset, i) => (
            <DiscoIconButton
              key={preset}
              testid={`${testid}.preset.${i}`}
              width={32}
              height={32}
              backgroundColor={preset}
              hoverBackgroundColor={lighten(preset, 0.2)}
              classes={{ root: classes.presetButton }}
              onClick={() => onChange(preset)}
            />
          ))}
          <DiscoIconButton
            width={32}
            height={32}
            variant={"outlined"}
            borderStyle={"dashed"}
            classes={{ root: classes.presetButton }}
            onClick={handleOnClick}
          >
            <DiscoIcon icon={"add"} />
          </DiscoIconButton>
        </div>
      )}
    </>
  )

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    // If we are attempting to clear the value, we should set the input
    // colour to #FFFFFF but render the value as # in the frontend
    if (e.target.value.length <= 1) {
      onChange("#FFFFFF")
      setRenderedValue("#")
      return
    }

    onChange(e.target.value)
    setRenderedValue(e.target.value)
  }

  // If the value is not a valid color, we should render #FFFFFF
  function renderBackground() {
    if (value === "#") return "#FFFFFF"
    else if (!value?.startsWith("#")) return "#FFFFFF"
    return value
  }

  function handleOnClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    if (disabled) return
    setShowPicker(true)
  }
}

export default DiscoColorPicker

const useStyles = makeUseStyles((theme) => ({
  colorBadge: {
    // Square badge
    height: "24px",
    width: "24px",
    flexShrink: 0,
    // Rounded border with outline for when color = background color
    borderRadius: theme.measure.borderRadius.default,
    border: `1px solid white`,
    outline: "none",
    cursor: "pointer",
  },
  paper: {
    padding: 0,
  },
  presetsContainer: {
    display: "flex",
    gap: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    flexWrap: "wrap",
  },
  presetButton: {
    borderRadius: 6,
    "& svg:not(:hover)": {
      color: theme.palette.text.secondary,
    },
  },
}))
