import { useDrawerContext } from "@/core/context/DrawerContext"
import ArrowStemLeftIcon from "@/core/ui/iconsax/linear/arrow-left.svg"
import useIsAdminViewingAsMember from "@/product/util/hook/useIsAdminViewingAsMember"
import useIsWebView from "@/product/util/hook/useIsWebView"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoIconButton from "@disco-ui/button/DiscoIconButton"
import { Drawer, useTheme } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import { getWebViewHeader, WebViewHeader } from "@utils/webView/webViewUtils"
import classNames from "classnames"

interface DiscoDrawerSidebarProps extends TestIDProps {
  children: React.ReactNode
  classes?: {
    sidebar?: string
  }
}

function DiscoDrawerSidebar({
  children,
  classes: customClasses,
}: DiscoDrawerSidebarProps) {
  const { isDrawerSidebarOpen, closeDrawerSidebar } = useDrawerContext()
  const isAdminViewingAs = useIsAdminViewingAsMember()
  const isWebView = useIsWebView()
  const webViewHeader = getWebViewHeader()

  const theme = useTheme()
  const isMobile = useIsMobile()
  const classes = useStyles({ isAdminViewingAs, isWebView, webViewHeader })

  if (!isMobile) return renderContent()

  return (
    <Drawer
      SlideProps={{
        timeout: 100,
        direction: "left",
      }}
      BackdropProps={{
        invisible: true,
      }}
      open={isDrawerSidebarOpen}
      onClose={closeDrawerSidebar}
      disableEnforceFocus
      classes={{ paper: classes.mobileOverlayDrawer }}
      disablePortal={!isMobile}
    >
      <DiscoIconButton
        className={classes.mobileOverlayDrawerCloseButton}
        onClick={closeDrawerSidebar}
        color={theme.palette.text.secondary}
      >
        <ArrowStemLeftIcon />
      </DiscoIconButton>
      {renderContent()}
    </Drawer>
  )

  function renderContent() {
    return (
      <div className={classNames(classes.sidebar, customClasses?.sidebar)}>
        {children}
      </div>
    )
  }
}

type StyleProps = {
  isAdminViewingAs: boolean
  isWebView: boolean
  webViewHeader: WebViewHeader | null
}

const useStyles = makeUseStyles((theme) => ({
  sidebar: {
    overflow: "hidden",
    borderLeft: `2px solid ${theme.palette.constants.divider}`,
    height: "100%",
    flexShrink: 0,
    width: "500px",

    [theme.breakpoints.down("md")]: {
      width: "420px",
    },

    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
      marginLeft: theme.spacing(0),
      width: "auto",
      borderLeft: "none",
    },
  },
  mobileOverlayDrawer: {
    position: "relative",
    padding: 0,
    borderRadius: theme.measure.borderRadius.big,

    margin: ({ isAdminViewingAs, isWebView }: StyleProps) => {
      if (isAdminViewingAs && !isWebView) {
        return theme.spacing(10.5, 0.5, 0.5, 0.5)
      }
      return theme.spacing(0.5)
    },
    marginTop: ({ webViewHeader }: StyleProps) => {
      if (!webViewHeader) return undefined
      return webViewHeader.insetTop
    },
    height: ({ isAdminViewingAs, isWebView, webViewHeader }: StyleProps) => {
      if (isWebView && webViewHeader) {
        return `calc(100% - ${webViewHeader.insetTop + theme.spacing(1.5)}px)`
      }

      if (isAdminViewingAs && !isWebView) {
        return `calc(100% - ${theme.spacing(11)}px)`
      }

      return `calc(100% - ${theme.spacing(1.5)}px)`
    },
  },
  mobileOverlayDrawerCloseButton: {
    position: "absolute",
    top: theme.spacing(3),
    left: theme.spacing(1),
    padding: theme.spacing(1.5),
    zIndex: theme.zIndex.drawer + theme.zIndex.raise1,
  },
}))

export default DiscoDrawerSidebar
