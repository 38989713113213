/**
 * @generated SignedSource<<407b1c70a2f2a4cb033bebb568ae5125>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "events" | "group_directory" | "link" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useRenderProductAppSettingsButtonsFragment$data = {
  readonly contentUsage: {
    readonly id: string;
  } | null;
  readonly customAppTitle: string | null;
  readonly feed: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly id: string;
  readonly kind: ProductAppKind;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelSettingsModalButtonFragment" | "ChatChannelSideBarItemFragment" | "ContentAppSettingsButtonFragment" | "DeleteChatChannelAppButtonFragment" | "DeleteNavFolderButtonFragment" | "FeedAppSettingsButtonFragment" | "GenericAppSettingsButtonFragment" | "LinkAppSettingsButtonFragment" | "MembersAppSettingsButtonFragment" | "NavFolderSettingsButtonFragment">;
  readonly " $fragmentType": "useRenderProductAppSettingsButtonsFragment";
};
export type useRenderProductAppSettingsButtonsFragment$key = {
  readonly " $data"?: useRenderProductAppSettingsButtonsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useRenderProductAppSettingsButtonsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useRenderProductAppSettingsButtonsFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Feed",
      "kind": "LinkedField",
      "name": "feed",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsage",
      "kind": "LinkedField",
      "name": "contentUsage",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentAppSettingsButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LinkAppSettingsButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedAppSettingsButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GenericAppSettingsButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatChannelSettingsModalButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatChannelSideBarItemFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteChatChannelAppButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MembersAppSettingsButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavFolderSettingsButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteNavFolderButtonFragment"
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};
})();

(node as any).hash = "799b09fea4af03324026fbcd54c91ec6";

export default node;
