/**
 * @generated SignedSource<<9d0c61a2c5a6e6480baa6115b7e94274>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnrollUserForProductLearnModeInput = {
  productId: string;
};
export type LearnModeSwitch_EnrollUserForProductLearnModeMutation$variables = {
  input: EnrollUserForProductLearnModeInput;
};
export type LearnModeSwitch_EnrollUserForProductLearnModeMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
      readonly isLearnMode: boolean;
      readonly product: {
        readonly chatChannels: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
            };
          }>;
        };
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"LearnModeSwitchFragment">;
      };
    } | null;
  };
};
export type LearnModeSwitch_EnrollUserForProductLearnModeMutation = {
  response: LearnModeSwitch_EnrollUserForProductLearnModeMutation$data;
  variables: LearnModeSwitch_EnrollUserForProductLearnModeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLearnMode",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ChatChannelNodeConnection",
  "kind": "LinkedField",
  "name": "chatChannels",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatChannelNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatChannel",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LearnModeSwitch_EnrollUserForProductLearnModeMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "EnrollUserForProductLearnModeResponse",
        "kind": "LinkedField",
        "name": "enrollUserForProductLearnMode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "LearnModeSwitchFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LearnModeSwitch_EnrollUserForProductLearnModeMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "EnrollUserForProductLearnModeResponse",
        "kind": "LinkedField",
        "name": "enrollUserForProductLearnMode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductMembership",
                    "kind": "LinkedField",
                    "name": "viewerMembership",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c753684d4dcfa4f7cad142997ee6eff",
    "id": null,
    "metadata": {},
    "name": "LearnModeSwitch_EnrollUserForProductLearnModeMutation",
    "operationKind": "mutation",
    "text": "mutation LearnModeSwitch_EnrollUserForProductLearnModeMutation(\n  $input: EnrollUserForProductLearnModeInput!\n) {\n  response: enrollUserForProductLearnMode(input: $input) {\n    node {\n      id\n      isLearnMode\n      product {\n        id\n        chatChannels {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n        ...LearnModeSwitchFragment\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment LearnModeSwitchFragment on Product {\n  id\n  name\n  status\n  viewerMembership {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e8897079bdc2520f3b7f0b1fff5beb84";

export default node;
