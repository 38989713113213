/**
 * @generated SignedSource<<58da994ba58e0f1f745319a5858f780a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscoAssetVideoQuery$variables = {
  id: string;
};
export type DiscoAssetVideoQuery$data = {
  readonly asset: {
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"DiscoVideoFragment">;
  } | null;
};
export type DiscoAssetVideoQuery = {
  response: DiscoAssetVideoQuery$data;
  variables: DiscoAssetVideoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscoAssetVideoQuery",
    "selections": [
      {
        "alias": "asset",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DiscoVideoFragment"
              }
            ],
            "type": "Asset",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DiscoAssetVideoQuery",
    "selections": [
      {
        "alias": "asset",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "muxAssetPlaybackId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aspectRatio",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoDuration",
                "storageKey": null
              }
            ],
            "type": "Asset",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "79d908119fc75a5a3b339b73ab542bc5",
    "id": null,
    "metadata": {},
    "name": "DiscoAssetVideoQuery",
    "operationKind": "query",
    "text": "query DiscoAssetVideoQuery(\n  $id: ID!\n) {\n  asset: node(id: $id) {\n    __typename\n    ... on Asset {\n      id\n      ...DiscoVideoFragment\n    }\n    id\n  }\n}\n\nfragment DiscoVideoFragment on Asset {\n  id\n  url\n  name\n  muxAssetPlaybackId\n  aspectRatio\n  videoDuration\n}\n"
  }
};
})();

(node as any).hash = "a869b763943a2ba8762877362380bde4";

export default node;
