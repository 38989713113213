import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { Checkbox, FormControlLabel, FormControlLabelProps } from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import CheckboxIcon from "../../core/ui/iconsax/bold/tick-square.svg"
import CheckboxUncheckedIcon from "../../core/ui/iconsax/linear/stop.svg"
import DiscoText, { DiscoTextSkeleton, GroovyTextColorKind } from "../text/DiscoText"

type DiscoCheckboxProps = {
  checked: boolean
  onChange?(checked: boolean): void
  sublabel?: string
  variant?: "default" | "ellipse"
  /**
   * If true, the checkbox label will be shown as a strikethrough when checked
   */
  strikethrough?: boolean
  /**
   * Provide a custom icon for the unchecked icon
   */
  customIcon?: React.ReactNode
  /**
   * Provide a custom icon for the checked icon
   */
  customCheckedIcon?: React.ReactNode
  testid?: string
  sublabelTextColor?: GroovyTextColorKind
  fullWidth?: boolean
} & Omit<FormControlLabelProps, "control" | "onChange">

const DiscoCheckbox: React.FC<DiscoCheckboxProps> = (props) => {
  const {
    checked,
    onChange,
    variant = "default",
    label,
    sublabel,
    strikethrough,
    customIcon,
    customCheckedIcon,
    testid,
    classes: controlClasses = {},
    sublabelTextColor,
    fullWidth,
    ...rest
  } = props

  const classes = useStyles({
    isDisabled: rest.disabled || false,
    checked,
    variant,
    fullWidth,
  })
  return (
    <>
      <FormControlLabel
        {...rest}
        classes={{
          root: classNames(classes.formControlRoot, controlClasses.root),
          label: controlClasses.label,
        }}
        control={
          <Checkbox
            disableRipple
            classes={{ root: classes.root }}
            icon={
              customIcon ||
              (variant === "default" ? (
                <CheckboxUncheckedIcon height={24} width={24} />
              ) : (
                <EllipseLabelIcon label={label} />
              ))
            }
            checkedIcon={
              customCheckedIcon ||
              (variant === "default" ? (
                <CheckboxIcon height={24} width={24} className={classes.checkbox} />
              ) : (
                <EllipseLabelIcon checked label={label} />
              ))
            }
            checked={checked}
            onChange={onChange ? () => onChange(!checked) : undefined}
            data-testid={`${testid}.input`}
            disabled={rest.disabled}
          />
        }
        label={
          // if we're using the ellipse variant, the custom icons handle the label
          variant === "ellipse" ? undefined : typeof label === "string" ? (
            <DiscoText
              data-testid={`${testid}.label`}
              className={classNames({
                [classes.strikethrough]: checked && strikethrough,
              })}
              // Style as body-sm unless sublabel is provided
              variant={sublabel ? "body-md" : "body-sm"}
              color={sublabel ? "text.primary" : "text.secondary"}
            >
              {label}
            </DiscoText>
          ) : (
            label
          )
        }
      />
      {sublabel && (
        <div className={classes.sublabel}>
          <DiscoText variant={"body-sm"} color={sublabelTextColor}>
            {sublabel}
          </DiscoText>
        </div>
      )}
    </>
  )
}

type SkeletonProps = Pick<DiscoCheckboxProps, "classes" | "className"> & {
  hideLabel?: boolean
}

export function DiscoCheckboxSkeleton({
  hideLabel,
  classes: controlClasses = {},
}: SkeletonProps) {
  const classes = useStyles({ isDisabled: true, checked: false, variant: "default" })

  return (
    <>
      <FormControlLabel
        classes={{
          root: classNames(classes.formControlRoot, controlClasses.root),
          label: controlClasses.label,
        }}
        control={
          <Checkbox
            disableRipple
            classes={{ root: classes.root }}
            icon={<CheckboxUncheckedIcon height={24} width={24} />}
            checkedIcon={
              <CheckboxIcon height={24} width={24} className={classes.checkbox} />
            }
            disabled
          />
        }
        label={hideLabel ? null : <DiscoTextSkeleton />}
      />
    </>
  )
}

function EllipseLabelIcon({ checked = false, label, variant }: any) {
  const classes = useStyles({ isDisabled: false, checked, variant })
  return (
    <span>
      {label &&
        (typeof label === "string" ? (
          <DiscoText className={classes.label} variant={"body-sm"}>
            {label.charAt(0)}
          </DiscoText>
        ) : (
          label
        ))}
    </span>
  )
}

type StyleProps = {
  isDisabled: boolean
  checked: boolean
  variant: "default" | "ellipse"
  fullWidth?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  formControlRoot: ({ isDisabled, fullWidth }: StyleProps) => ({
    width: fullWidth ? "100%" : "auto",
    color: theme.palette.groovy.grey[200],
    "&.Mui-disabled": {
      cursor: "not-allowed",
    },
    "&:hover:not(.Mui-checked)": {
      backgroundColor: "transparent",

      "& svg rect": {
        color: isDisabled ? theme.palette.groovy.grey[200] : theme.palette.primary.main,
      },
    },
  }),
  root: ({ checked, variant, isDisabled }: StyleProps) => {
    switch (variant) {
      case "default":
        return {
          color: theme.palette.groovy.grey[200],
          "&.Mui-disabled": {
            color: theme.palette.groovy.grey[200],
          },
          "&:hover:not(.Mui-checked)": {
            backgroundColor: "transparent",

            "& svg rect": {
              color: isDisabled
                ? theme.palette.groovy.grey[200]
                : theme.palette.primary.main,
            },
          },
          "&:hover": {
            backgroundColor: "transparent !important",
          },
        }
      case "ellipse":
        return {
          borderRadius: "100%",
          color: theme.palette.text.primary,
          width: "24px",
          height: "24px",
          textAlign: "unset",
          backgroundColor: checked
            ? theme.palette.groovy.blue[400]
            : theme.palette.groovy.grey[100],
          [theme.breakpoints.down("xs")]: {
            flex: "1 1 auto",
          },
          "&:hover": {
            "& $label": {
              color: checked
                ? theme.palette.groovy.blue[400]
                : theme.palette.text.primary,
            },
            backgroundColor: checked
              ? `${theme.palette.groovy.blue[100]} !important`
              : `transparent !important`,
          },
        }
    }
  },
  sublabel: {
    paddingLeft: theme.spacing(4),
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(2),
  },
  strikethrough: {
    textDecoration: "line-through",
    color: theme.palette.groovy.grey[200],
  },
  checkbox: ({ isDisabled }: StyleProps) => ({
    color: isDisabled ? theme.palette.groovy.grey[200] : theme.palette.primary.main,
  }),
  label: ({ checked }: StyleProps) => ({
    color: checked ? theme.palette.groovy.grey[100] : theme.palette.text.primary,
  }),
}))

export default DiscoCheckbox
