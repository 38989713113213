/**
 * @generated SignedSource<<7839ef8cd72ee4b552fa0da813a8c801>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InteractivePlayerOverviewTabContentFragment$data = {
  readonly chapters: ReadonlyArray<{
    readonly startTime: number;
    readonly timecode: string;
    readonly title: string;
  }> | null;
  readonly id: string;
  readonly summary: string | null;
  readonly " $fragmentType": "InteractivePlayerOverviewTabContentFragment";
};
export type InteractivePlayerOverviewTabContentFragment$key = {
  readonly " $data"?: InteractivePlayerOverviewTabContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InteractivePlayerOverviewTabContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InteractivePlayerOverviewTabContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetTranscriptionChapter",
      "kind": "LinkedField",
      "name": "chapters",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timecode",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssetTranscription",
  "abstractKey": null
};

(node as any).hash = "509574608227c290ae28e0cd3241eded";

export default node;
