import SortIcon from "@/core/ui/iconsax/linear/arrow-3.svg"
import { OrderByInput } from "@/product/reports/table/__generated__/ProgressReportTablePaginationQuery.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import {
  DiscoButton,
  DiscoDropdown,
  DiscoIconButton,
  DiscoIconKinds,
  DiscoText,
  DiscoTextSkeleton,
  SelectOption,
} from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DropdownIcon from "@disco-ui/dropdown/DropdownIcon"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

export type DiscoTableSortDirection = "ASC" | "DESC"

interface DiscoTableSortDropdownProps extends TestIDProps {
  options: DiscoTableSortOption[]
  onOptionSelect: (opt: DiscoTableSortOption, direction: DiscoTableSortDirection) => void
  toolbarState: DiscoTableToolbarStateWithSort
  className?: string
}

export type DiscoTableToolbarState = {
  search: string
}

export type DiscoTableToolbarStateWithSort = {
  search?: string
  sort: {
    id: string
    order: { field: string }
    title: string
  }
  direction: DiscoTableSortDirection
  viewType?: string
}

export type DiscoTableSortOption = SelectOption<
  string,
  {
    order: Omit<OrderByInput, "direction">
    icon?: React.ReactNode
    onlyDirection?: DiscoTableSortDirection
  }
>

function DiscoTableSortDropdown({
  testid,
  options,
  onOptionSelect,
  toolbarState,
  className: customClassName,
}: DiscoTableSortDropdownProps) {
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles()

  return (
    <DiscoDropdown
      testid={`${testid}.sort-select`}
      menuClasses={{
        paper: classes.menu,
      }}
      menuButton={(btnProps) => (
        <DiscoButton
          {...btnProps}
          testid={`${testid}.sort-select.button`}
          className={classNames(classes.dropdownButton, customClassName)}
        >
          {toolbarState.sort.title}
        </DiscoButton>
      )}
    >
      <DiscoText
        variant={"body-xs-500"}
        marginTop={0.5}
        marginBottom={0.5}
        color={"groovy.grey.400"}
      >
        {"Sort by..."}
      </DiscoText>

      {/* Sort Options */}
      {options.map((option, index) => {
        const isSelected = toolbarState.sort.id === option.value
        const direction =
          option.context?.onlyDirection ||
          (isSelected
            ? // If the option is selected, toggle the direction
              toolbarState.direction === "ASC"
              ? "DESC"
              : "ASC"
            : // Otherwise, use descending
              "DESC")

        return (
          <DiscoDropdownItem
            key={option.value}
            title={option.title}
            icon={option.context!.icon as DiscoIconKinds}
            onClick={() => onOptionSelect(option, direction)}
            testid={`${testid}.sort-select.item-${index}`}
            className={showOnHoverClasses.hoverable}
            rightButton={
              <DiscoIconButton
                classes={{
                  root: classNames(classes.sortButton, showOnHoverClasses.showable),
                }}
                svgStyles={{ height: 16, width: 16 }}
              >
                <SortIcon />
              </DiscoIconButton>
            }
            tooltip={direction === "ASC" ? "Sort Ascending" : "Sort Descending"}
          />
        )
      })}
    </DiscoDropdown>
  )
}

const useStyles = makeUseStyles((theme) => ({
  dropdownButton: {
    minWidth: "150px",
    justifyContent: "space-between",
  },
  menu: {
    minWidth: "180px",
  },
  sortButton: {
    padding: 0,
    paddingLeft: theme.spacing(1),
    "& span > svg": {
      // The sort icon should be groovy.grey.400 to match the other icon hover state
      // Use important! to override the default hover color in DiscoDropdownItem
      color: `${theme.palette.groovy.grey[400]} !important`,
    },
  },
}))

type DiscoTableSortDropdownSkeletonProps = {
  width?: string
}

export const DiscoTableSortDropdownSkeleton = ({
  width,
}: DiscoTableSortDropdownSkeletonProps) => {
  const classes = useStyles()

  return (
    <DiscoButton
      rightIcon={<DropdownIcon />}
      color={"grey"}
      variant={"outlined"}
      className={classes.dropdownButton}
    >
      <DiscoTextSkeleton width={width || 75} />
    </DiscoButton>
  )
}

export default DiscoTableSortDropdown
