import {
  postWebViewMessage,
  WebViewMessageType,
} from "@/apps/util/hooks/useWebViewMessage"
import useIsWebView from "@/product/util/hook/useIsWebView"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Modal, { ModalProps } from "@components/modal/Modal"
import {
  DiscoButton,
  DiscoButtonProps,
  DiscoIcon,
  DiscoIconKinds,
  DiscoText,
  DiscoThemeProvider,
} from "@disco-ui"
import { darken, useTheme } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import classNames from "classnames"
import { useEffect } from "react"

export interface WarningModalProps extends ModalProps {
  title: string
  /** Default is 'danger' */
  variant?: DiscoWarningModalVariant
  description: React.ReactNode
  body?: React.ReactNode
  classes?: ClassNameMap<"buttonsWrapper">
  testid: string
  customConfirmationButton?: React.ReactNode
  confirmationButtonProps?: DiscoButtonProps
  cancelButtonText?: string | null
  shouldDisplayCancelSpinner?: boolean
  icon?: DiscoIconKinds
  hideIcon?: boolean
  onCancel?: () => void
  alignTitle?: "left" | "center" | "right"
}

type DiscoWarningModalVariant = "error" | "warning" | "primary"

function DiscoWarningModal({
  isOpen,
  onClose,
  title,
  variant = "error",
  description,
  body,
  testid,
  classes: customClasses,
  customConfirmationButton,
  confirmationButtonProps,
  parentSelector,
  modalContentLabel,
  /** Default is 'Cancel' */
  cancelButtonText = "Cancel",
  shouldDisplayCancelSpinner = false,
  icon,
  hideIcon = false,
  onCancel,
  alignTitle = "center",
}: WarningModalProps) {
  const classes = useStyles({ variant, alignTitle })
  const appTheme = useTheme()
  const isWebView = useIsWebView()

  // Trigger shadow on mobile header
  useEffect(() => {
    if (!isWebView) return

    postWebViewMessage({
      type: WebViewMessageType.toggle_disco_modal,
      value: isOpen,
    })

    return () => {
      postWebViewMessage({
        type: WebViewMessageType.toggle_disco_modal,
        value: false,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  function getIcon(): { icon: DiscoIconKinds; iconColor: string } {
    switch (variant) {
      case "primary":
        return { icon: icon || "info", iconColor: appTheme.palette.primary.main }
      case "error":
        return { icon: "warning", iconColor: appTheme.palette.text.danger }
      case "warning":
        return { icon: "warning", iconColor: appTheme.palette.orange.main }
    }
  }

  return (
    <DiscoThemeProvider theme={appTheme}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        parentSelector={parentSelector}
        customClassName={classes.container}
        modalContentLabel={modalContentLabel}
      >
        <div className={classes.header}>
          {!hideIcon && (
            <div className={classes.iconWrapper}>
              <DiscoIcon
                icon={getIcon().icon}
                color={getIcon().iconColor}
                className={classes.icon}
              />
            </div>
          )}
          <DiscoText variant={"heading-sm"} data-testid={`${testid}.title`}>
            {title}
          </DiscoText>
        </div>
        <div className={classes.body}>
          <DiscoText data-testid={`${testid}.description`}>{description}</DiscoText>
          {body}
          <div
            className={classNames(classes.buttonsWrapper, customClasses?.buttonsWrapper)}
          >
            {cancelButtonText !== null && (
              <DiscoButton
                data-testid={`${testid}.cancel-button`}
                width={"100%"}
                color={"grey"}
                variant={"outlined"}
                onClick={onCancel || onClose}
                shouldDisplaySpinner={shouldDisplayCancelSpinner}
                disabled={shouldDisplayCancelSpinner}
              >
                {cancelButtonText}
              </DiscoButton>
            )}
            {customConfirmationButton ||
              (confirmationButtonProps && (
                <DiscoButton
                  color={variant}
                  width={"100%"}
                  {...confirmationButtonProps}
                  disabled={
                    confirmationButtonProps?.shouldDisplaySpinner ||
                    confirmationButtonProps?.disabled
                  }
                  data-testid={`${testid}.confirm-button`}
                >
                  {confirmationButtonProps?.children}
                </DiscoButton>
              ))}
          </div>
        </div>
      </Modal>
    </DiscoThemeProvider>
  )
}

type StyleProps = {
  variant: DiscoWarningModalVariant
  alignTitle: "left" | "center" | "right"
}

const useStyles = makeUseStyles((theme) => {
  function getIconColor(variant: DiscoWarningModalVariant) {
    switch (variant) {
      case "primary":
        return theme.palette.type === "light"
          ? theme.palette.primary.light
          : darken(theme.palette.primary.dark, 0.5)
      case "error":
        return theme.palette.error.light
      case "warning":
        return theme.palette.orange.light
    }
  }
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "480px",
      padding: "24px",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        padding: "16px",
        marginRight: "12px",
      },
      gap: theme.spacing(3),
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.palette.groovyDepths.insideCard,
      borderRadius: theme.measure.borderRadius.xl,
    },
    buttonsWrapper: {
      display: `flex`,
      gap: theme.spacing(1),
      justifyContent: "center",
    },
    header: ({ alignTitle }: StyleProps) => ({
      display: "grid",
      justifyItems: alignTitle,
      marginTop: theme.spacing(1),
      gap: theme.spacing(2),
      width: "100%",
    }),
    icon: {
      maxWidth: "24px",
      maxHeight: "24px",
    },
    iconWrapper: ({ variant }: StyleProps) => ({
      borderRadius: "12px",
      width: "40px",
      height: "40px",
      display: "grid",
      justifyItems: "center",
      alignContent: "center",
      backgroundColor: getIconColor(variant),
    }),
    body: {
      width: "100%",
      color: theme.palette.text.secondary,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
  }
})

export default DiscoWarningModal
