import Icon from "@/core/ui/iconsax/bold/arrow-down.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import classNames from "classnames"

type Props = {
  className?: string
}

export default function DropdownIcon(props: Props) {
  const { className: customClassName, ...rest } = props
  const classes = useStyles()

  return <Icon className={classNames(classes.root, customClassName)} {...rest} />
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    // Dropdown icon has a default color that's different than regular color
    // so we need to override the color in case it is rendered inside a DiscoButton, etc
    color: `${
      theme.palette.type === "dark"
        ? theme.palette.groovy.neutral[600]
        : theme.palette.groovy.neutral[500]
    } !important`,
  },
}))
