import makeUseStyles from "@assets/style/util/makeUseStyles"
import classNames from "classnames"

interface DiscoLoaderDotsProps {
  className?: string
  size?: "small" | "default"
}

function DiscoLoaderDots({ className, size = "default" }: DiscoLoaderDotsProps) {
  const classes = useStyles({ size })

  return (
    <div className={classNames(classes.container, className)}>
      <div className={classNames(classes.dot, classes.dot1)} />
      <div className={classNames(classes.dot, classes.dot2)} />
      <div className={classNames(classes.dot, classes.dot3)} />
    </div>
  )
}

interface StyleProps {
  size: "small" | "default"
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dot: {
    width: ({ size }: StyleProps) => (size === "small" ? 12 : 36),
    height: ({ size }: StyleProps) => (size === "small" ? 12 : 36),
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    margin: ({ size }: StyleProps) => theme.spacing(size === "small" ? 0.25 : 0.5),
    animation: "$pulse 900ms ease-in-out infinite",
  },
  dot1: {
    animationDelay: "0ms",
  },
  dot2: {
    animationDelay: "150ms",
  },
  dot3: {
    animationDelay: "300ms",
  },
  "@keyframes pulse": {
    "0%, 100%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(0.5)",
    },
  },
}))

export default DiscoLoaderDots
