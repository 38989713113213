/**
 * @generated SignedSource<<bad3d186d4e879922787459a7cd19dd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductRegistrationAvailability = "hidden" | "private" | "public" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
export type ProductMultiSelectQuery$variables = {
  forMembershipPlanId?: string | null;
  hideNonPublic: boolean;
  id: string;
  types?: ReadonlyArray<ProductType> | null;
};
export type ProductMultiSelectQuery$data = {
  readonly node: {
    readonly __typename: "Organization";
    readonly id: string;
    readonly products: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly isAutoJoined: boolean;
          readonly name: string;
          readonly registrationAvailability: ProductRegistrationAvailability;
          readonly slug: string;
          readonly " $fragmentSpreads": FragmentRefs<"MembershipPlanExperienceDetailsFragment" | "ProductDropdownItemFragment" | "ProductWithDetailsFragment">;
        };
      }>;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type ProductMultiSelectQuery = {
  response: ProductMultiSelectQuery$data;
  variables: ProductMultiSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "forMembershipPlanId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hideNonPublic"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "types"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "forMembershipPlanId",
    "variableName": "forMembershipPlanId"
  },
  {
    "kind": "Variable",
    "name": "hideNonPublic",
    "variableName": "hideNonPublic"
  },
  {
    "kind": "Variable",
    "name": "types",
    "variableName": "types"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registrationAvailability",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAutoJoined",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductMultiSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "ProductNodeConnection",
                "kind": "LinkedField",
                "name": "products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ProductDropdownItemFragment"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ProductWithDetailsFragment"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "MembershipPlanExperienceDetailsFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProductMultiSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "ProductNodeConnection",
                "kind": "LinkedField",
                "name": "products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startsAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endsAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Badge",
                            "kind": "LinkedField",
                            "name": "badge",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "icon",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "emoji",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mediaUrl",
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Pricing",
                            "kind": "LinkedField",
                            "name": "pricing",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "frequency",
                                "storageKey": null
                              },
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amountCents",
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Tag",
                            "kind": "LinkedField",
                            "name": "typeTag",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "label",
                                "storageKey": null
                              },
                              (v13/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cover",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "506a3a34921f42aeb9da852f196316fe",
    "id": null,
    "metadata": {},
    "name": "ProductMultiSelectQuery",
    "operationKind": "query",
    "text": "query ProductMultiSelectQuery(\n  $id: ID!\n  $hideNonPublic: Boolean!\n  $types: [ProductType!]\n  $forMembershipPlanId: ID\n) {\n  node(id: $id) {\n    __typename\n    ... on Organization {\n      __typename\n      id\n      products(hideNonPublic: $hideNonPublic, types: $types, forMembershipPlanId: $forMembershipPlanId) {\n        edges {\n          node {\n            id\n            name\n            slug\n            registrationAvailability\n            isAutoJoined\n            ...ProductDropdownItemFragment\n            ...ProductWithDetailsFragment\n            ...MembershipPlanExperienceDetailsFragment\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment BadgeFragment on Badge {\n  kind\n  color\n  icon\n  emoji\n  mediaUrl\n}\n\nfragment MembershipPlanExperienceDetailsFragment on Product {\n  id\n  name\n  startsAt\n  endsAt\n  type\n  slug\n  cover\n  isAutoJoined\n  ...ProductTypeTagFragment\n}\n\nfragment ProductDropdownItemFragment on Product {\n  name\n  type\n  startsAt\n  endsAt\n  status\n  badge {\n    ...BadgeFragment\n    id\n  }\n}\n\nfragment ProductTypeTagFragment on Product {\n  typeTag {\n    label\n    color\n    id\n  }\n}\n\nfragment ProductWithDetailsFragment on Product {\n  id\n  name\n  startsAt\n  endsAt\n  type\n  slug\n  pricing {\n    ...pricingUtils_usePricingDisplayValue\n    id\n  }\n  ...ProductTypeTagFragment\n  badge {\n    ...BadgeFragment\n    id\n  }\n  cover\n}\n\nfragment pricingUtils_usePricingDisplayValue on Pricing {\n  frequency\n  kind\n  amountCents\n}\n"
  }
};
})();

(node as any).hash = "30d2caaccb734524dd9173c27fc0f11e";

export default node;
